import { useState, useEffect } from 'react';

export function useIsDebugMenuOpen() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastFiveCharacters, setLastFiveCharacters] = useState<string[]>([]);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }

      setLastFiveCharacters((prev) => [...prev.slice(-4), event.key]);
    };
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, []);

  useEffect(() => {
    if (lastFiveCharacters.join('').toLowerCase() === 'debug') {
      setIsOpen(true);
    }
  }, [lastFiveCharacters]);

  const closeMenu = () => setIsOpen(false);

  return [isOpen, closeMenu] as const;
}
