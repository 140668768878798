import { useState } from 'react';
import { Item } from 'react-stately';
import { useLocalStorage } from 'react-use';
import {
  type FeatureFlags,
  flagDefinitions,
} from '@hungryroot/feature-flag-defs';
import { X } from '@hungryroot/icons';
import {
  Button,
  Heading,
  IconButton,
  Select,
  TextInput,
} from '@hungryroot/ramen';

import styles from './FeatureFlags.module.css';

export function FeatureFlags() {
  const [selectedFlag, setSelectedFlag] = useState<FeatureFlags | undefined>(
    undefined
  );
  const [flagValue, setFlagValue] = useState<FeatureFlags | undefined>(
    undefined
  );

  const options = Object.entries(flagDefinitions)
    .filter(([key]) => {
      return key.includes('feature-flag');
    })
    .map(([key, definition]) => ({
      label: definition.name || key,
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const [existingOverrides, setExistingOverrides] = useLocalStorage<
    Record<string, string>
  >('feature-flags.overrides', {});

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!(selectedFlag && flagValue)) {
      return;
    }

    setExistingOverrides({
      ...existingOverrides,
      [selectedFlag]: flagValue,
    });

    setSelectedFlag(undefined);
    setFlagValue(undefined);
  };

  return (
    <div className={styles.container}>
      <Heading variant="H3">Feature Flags</Heading>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Select
          label="Select Feature Flag"
          onSelectionChange={(key) => {
            setSelectedFlag(key as FeatureFlags);
          }}
          selectedKey={selectedFlag}
          items={[{ label: 'Select one...', value: '' }, ...options]}
        >
          {(item) => <Item key={item.value}>{item.label}</Item>}
        </Select>

        {selectedFlag && (
          <TextInput
            label="Flag Value"
            value={flagValue}
            onChange={(value) => {
              setFlagValue(value as FeatureFlags);
            }}
          />
        )}

        <Button
          buttonType="submit"
          style={!selectedFlag || !flagValue ? 'disabled' : 'default'}
          prominence="tertiary"
          size="small"
          className={styles.button}
        >
          Save flag
        </Button>
      </form>

      {existingOverrides && (
        <div className={styles.list}>
          {Object.entries(existingOverrides).map(([key]) => (
            <div key={key} className={styles['list-item']}>
              <div className={styles['list-item']}>
                <span>{key}</span>
                <span>{existingOverrides[key]}</span>
              </div>
              <IconButton
                icon={<X />}
                onClick={() => {
                  const updatedOverrides = { ...existingOverrides };
                  delete updatedOverrides[key];
                  setExistingOverrides(updatedOverrides);
                }}
                style="critical"
                prominence="tertiary"
                size="small"
                ariaLabel={`Remove ${key} flag override`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
