import { X } from '@hungryroot/icons';
import { Button } from '@hungryroot/ramen';
import { useIsDebugMenuOpen } from '../../hooks/useIsDebugMenuOpen';

import styles from './CloseButton.module.css';

export function CloseButton() {
  const [, closeMenu] = useIsDebugMenuOpen();

  return (
    <div className={styles.close}>
      <Button
        testid="close-debug-menu"
        onClick={() => closeMenu()}
        textPrimary=""
        iconPosition="right"
        icon={<X />}
        style="muted"
        prominence="tertiary"
        size="small"
      />
    </div>
  );
}
