import { useQueryClient } from '@tanstack/react-query';
import { Item } from 'react-stately';
import {
  invalidateCustomerExperiments,
  useCustomerExperiments,
} from '@hungryroot/experiments';
import { Heading, Paragraph, Select } from '@hungryroot/ramen';
import { enrollExperiments } from '@hungryroot/rest/api/enroll_experiments/post';
import { useFilteredExperiments } from '../../hooks/useFilteredExperiments';

import styles from './Experiments.module.css';

export function Experiments() {
  const queryClient = useQueryClient();
  const { data: customerExperiments, isLoading: customerExperimentsLoading } =
    useCustomerExperiments();

  const { data: filteredExperiments, isLoading: experimentsLoading } =
    useFilteredExperiments();

  if (customerExperimentsLoading || experimentsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <section className={styles.section}>
      <Heading variant="H3">Experiments</Heading>
      <div className={styles['section-content']}>
        {filteredExperiments.map((experiment) => {
          const customerExperiment = customerExperiments?.experiments.find(
            (e) => e.experiment === experiment.id
          );

          return (
            <div key={experiment.id} className={styles.experiment}>
              <Paragraph variant="h7">{experiment.name}</Paragraph>
              <Select
                className={styles.select}
                aria-label={experiment.name}
                testid={`experiment-${experiment.id}`}
                items={[
                  { label: -1, value: -1 },

                  ...experiment.variants.map((v) => ({
                    label: (Number(v.trim()) % 10).toString(),
                    value: v.trim(),
                  })),
                ]}
                selectedKey={customerExperiment?.variant?.toString()}
                onSelectionChange={async (key) => {
                  await enrollExperiments({
                    experiment: experiment.id,
                    variant: parseInt(key.toString()),
                    force: true,
                  });

                  invalidateCustomerExperiments(queryClient);
                }}
                name={experiment.id.toString()}
              >
                {(item) => (
                  <Item key={item.value} textValue={item.label.toString()}>
                    {item.label}
                  </Item>
                )}
              </Select>
            </div>
          );
        })}
      </div>
    </section>
  );
}
