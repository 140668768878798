import clsx from 'clsx';
import { CloseButton } from './components/CloseButton/CloseButton';
import { Experiments } from './components/Experiments/Experiments';
import { FeatureFlags } from './components/FeatureFlags/FeatureFlags';
import { useIsDebugMenuOpen } from './hooks/useIsDebugMenuOpen';

import styles from './DebugMenu.module.css';

export function DebugMenu() {
  const [isOpen, closeMenu] = useIsDebugMenuOpen();

  return (
    <div
      className={clsx({
        [styles['underlay']]: true,
        [styles.open]: isOpen,
      })}
      onClick={closeMenu}
    >
      <div
        className={clsx({
          [styles['debug-menu']]: true,
          [styles.open]: isOpen,
        })}
        onClick={(e) => {
          const target = e.target as HTMLElement;
          if (!target.closest('[data-testid="close-debug-menu"]')) {
            e.stopPropagation();
          }
        }}
      >
        <CloseButton />
        <Experiments />
        <FeatureFlags />
      </div>
    </div>
  );
}
