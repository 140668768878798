import { useMemo } from 'react';
import { useExperiments } from '@hungryroot/experiments';

// Experiments that are still active but we would never want to show in the UI
const EXPERIMENTS_HIDDEN_FROM_UI = [14100, 14600, 15500, 14601, 16500];

export const useFilteredExperiments = () => {
  const { data: experimentsData, isLoading: experimentsLoading } =
    useExperiments();

  const filteredExperiments = useMemo(() => {
    return (experimentsData?.results || [])
      .map((experiment) => {
        if (experiment.variants === null || experiment.is_active === false) {
          return null;
        }

        const variants = experiment.variants.split(',');

        if (variants.length < 2) {
          return null;
        }

        return {
          ...experiment,
          variants,
        };
      })
      .filter((experiment) => !!experiment)
      .filter((experiment) => {
        return !EXPERIMENTS_HIDDEN_FROM_UI.find((e) => e === experiment.id);
      })
      .sort((a, b) => (a.id > b.id ? -1 : 1));
  }, [experimentsData]);

  return {
    data: filteredExperiments,
    isLoading: experimentsLoading,
  };
};
