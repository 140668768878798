import { AUTHORIZATION_HEADER } from '../../../src/authorization';
import { send } from '../../../src/network';

type Params = {
  experiment: number;
  variant: number;
  force: boolean;
};

export function enrollExperiments({ force, experiment, variant }: Params) {
  return send<unknown>({
    path: '/api/enroll_experiments/',
    method: 'POST',
    query: new URLSearchParams({
      exp: experiment.toString(),
      variant: variant.toString(),
      force: force.toString(),
    }),
    headers: new Headers({
      AUTHORIZATION: AUTHORIZATION_HEADER,
    }),
  });
}
